import React from 'react'
import RadioObjectGroup from '../../radio-object-group/RadioObjectGroup'
import { getNumberOfRowsFromPolesString, getPolesCountFromPolesString, getNumberOfPolesFromStringExpression, canAddDeviceToBoardPosition } from '../../../utils/helpers'
import { intersectionWith, isEqual, last, first, orderBy } from 'lodash'
import BusbarService from '../../../services/BusbarService'

// Some useful facts
// - Circuit breakers are always MCBs. They can’t be anything else (e.g. RCBOs can never be main switches).

export default class BusBarFormContainer extends React.Component {
    constructor(props) {
        super(props)

        this.updateFittedOptionPosition = this.updateFittedOptionPosition.bind(this)
        this.updateBreakerPosition = this.updateBreakerPosition.bind(this)
        this.updateContactorPosition = this.updateContactorPosition.bind(this)
        this.updateMainSwitchPosition = this.updateMainSwitchPosition.bind(this)

        // Internal states that no one else needs to know about, holds info on the selection options
        this.initStates(this.props)
    }

    initStates(propsData, useSetState = false) {
        const updatedState = {
            errorInfo: '',
            isAllowedToProceed: true,

            // Props data
            boardRows: getNumberOfRowsFromPolesString(propsData.polesNeeded),
            boardRowPoles: getPolesCountFromPolesString(propsData.polesNeeded),

            // User selection
            MountingPosition: '',
            RowSelection: '',
            BusBarPhases: '',
            BusBarPoles: '',
            BusBarExtraPoles: 0,

            // Computed, helpers
            selectedBusBarData: null, // Will select a busbar data as soon as it's possible to determinate exact busbar based on user selections
            isExtraPolesFieldEnabled: false
        }

        if (useSetState) {
            this.setState(updatedState)
        } else {
            this.state = updatedState
        }
    }

    updateBreakerPosition(itemId, rowIndex, poleIndex, note, skipChecks) {
        this.props.updateBreakerPosition(itemId, rowIndex, poleIndex, note, skipChecks)
    }

    updateContactorPosition(itemId, rowIndex, poleIndex, note, skipChecks) {
        this.props.updateContactorPosition(itemId, rowIndex, poleIndex, note, skipChecks)
    }

    updateFittedOptionPosition(itemId, rowIndex, poleIndex, note, skipChecks) {
        this.props.updateFittedOptionPosition(itemId, rowIndex, poleIndex, note, skipChecks)
    }

    updateMainSwitchPosition(updateMainSwitchPosition, skipChecks) {
        this.props.updateMainSwitchPosition(updateMainSwitchPosition, skipChecks)
    }

    getMainSwitchDetails() {
        return this.props.boardOptionData.MainSwitches.find(mainSwitch => mainSwitch.ItemID === this.props.mainSwitchID)
    }

    getEnabledRowSelections() {
        let enabledOptions = []

        // Not DRY, but very easy to read instead of some fancy loop
        if (this.state.boardRows === 1) enabledOptions = ['1']
        if (this.state.boardRows === 2) enabledOptions = ['1', '2']
        if (this.state.boardRows === 3) enabledOptions = ['1', '2', '3']
        if (this.state.boardRows === 4) enabledOptions = ['1', '2', '3', '4']

        return enabledOptions
    }

    compatibleBusbarDevices = {
        'top': ['SLIM 1P+N', '36 mm 1P+N', '3P+N'],
        'bottom': ['SLIM 1P+N']
    }
    // This function accepts the array of all breakers and the mounting position in a row and returns all breakers between
    // first and last connectable devices (including them too)
    getBusbarConnectableDevices(breakers, mountingPosition) {
        if (!breakers || breakers.length === 0 || !mountingPosition) return []

        const position = mountingPosition.toLowerCase()
        const orderedBreakers = orderBy(breakers, ['PoleIndex'])
        const firstConnectableDeviceIndex = orderedBreakers.findIndex(breaker => 
            this.compatibleBusbarDevices[position].indexOf(breaker.BreakerTypeReadOnly.NumberOfPoles) > -1)
        const lastConnectableDeviceIndex = orderedBreakers.findLastIndex(breaker => 
            this.compatibleBusbarDevices[position].indexOf(breaker.BreakerTypeReadOnly.NumberOfPoles) > -1)
        const connectableDevices = orderedBreakers.slice(firstConnectableDeviceIndex, lastConnectableDeviceIndex + 1)

        return connectableDevices
    }

    // Given an array of breakers and a mounting position it will return an array of all incompatible devices for provided mounting position
    getIncompatibleBusbarBreakers(breakers, mountingPosition) {
        if (!breakers || breakers.length === 0 || !mountingPosition) return []

        const position = mountingPosition.toLowerCase()

        return breakers.filter(breaker => this.compatibleBusbarDevices[position].indexOf(breaker.BreakerTypeReadOnly.NumberOfPoles) === -1)
    }
    
    getEnabledPhasesOptions(returnErrors) {
        let enabledOptions = []
        let errors = []

        // When a single phase main incomer (switch or MCB) is selected then the options available for busbar should be Single phase only
        const mainSwitchDetails = this.getMainSwitchDetails()
        const selectedRowIndex = parseInt(this.state.RowSelection, 10) - 1
        const existingRowBreakers = this.props.breakersAttachedToTheBoard.filter(existingBreaker => existingBreaker.RowIndex === selectedRowIndex)
        const connectableDevices = this.getBusbarConnectableDevices(existingRowBreakers, this.state.MountingPosition)
        const any3PNRCBO = connectableDevices.some(breaker => breaker.BreakerTypeReadOnly.NumberOfPoles === '3P+N')
        const any36MM1PNRCBO = connectableDevices.some(breaker => breaker.BreakerTypeReadOnly.NumberOfPoles === '36 mm 1P+N')

        // First row is the row with the main switch in the current project, this can be different in the future
        if (this.state.RowSelection === '1') {
            // In case of bottom mounted busbar, customer can have a single or 3 phase busbar irrespective of the size of main incomer.
            if (this.state.MountingPosition === 'Bottom') {
                // From the client: "36mm 1P+N RCBOs are not compatible with bottom mounting busbar in any row."
                if (any36MM1PNRCBO) {
                    enabledOptions = []
                    let error = '36mm 1P+N RCBOs are not compatible with bottom mounting busbar in any row.'
                    errors.push(error)
                } else {
                    if (mainSwitchDetails.IncomerPhase === 'Single Phase') {
                        enabledOptions = ['Single Phase']
                    } else {
                        // If the main incomer is 3 phase then the user can add 3P+N RCBO. Also, in case of bottom mounted busbar the user will have the 
                        // option of either adding a 1 phase or 3 phase busbar in the top row with main switch. So we need to put in specific condition 
                        // in this case that if a row has 3P+N RCBO then user can’t add single phase busbar.
                        // + as mentioned by the client on CLIP-332, on first row: "if the user adds 3 phase RCBO in first row with main switch then the 
                        // user must not be allowed to add 3 phase busbar in the first row since 3 phase bottom connecting busbar is not compatible with 3 phase RCBO"
                        if (any3PNRCBO) {
                            enabledOptions = []
                            let error = '3P+N RCBOs are not compatible with bottom mounting busbar for a 3 phase main incomer.'
                            errors.push(error)
                        } else {
                            enabledOptions = ['Single Phase', '3 Phase']
                        }
                    }
                }
            } else if (this.state.MountingPosition === 'Top') {
                if (mainSwitchDetails.IncomerPhase === 'Single Phase') {
                    enabledOptions = ['Single Phase']
                } else if (mainSwitchDetails.IncomerPhase === '3 Phase') {
                    enabledOptions = ['3 Phase']
                } else {
                    enabledOptions = []
                    let error = 'No available busbar options for the 2 phase main incomer'
                    errors.push(error)
                }
            }
        } else {
            // Block for all other rows
            if (this.state.MountingPosition === 'Bottom') {
                // From the client: "36mm 1P+N RCBOs are not compatible with bottom mounting busbar in any row."
                if (any36MM1PNRCBO) {
                    enabledOptions = []
                    let error = '36mm 1P+N RCBOs are not compatible with bottom mounting busbar in any row.'
                    errors.push(error)
                } else {
                    /*  
                        It was mentioned that: "In case of bottom mounted busbar, customer can have a single or 3 phase busbar in any row irrespective 
                        of the size of main incomer.", but also: "... a 3 phase bottom mounted busbar (because bottom mounted busbars only connect to SLIM RCBOs) will not connect to 3P+N RCBO.", so in this case of Bottom mounting position we are going to enable both Single and 3 phase, until there is a 3P+N
                        RCBO device, otherwise user can't select a busbar.

                        Also CLIP-344 for the comment above: "This comment was for 3 phase incomer and if there are compatible products in that row."
                    */
                    if (any3PNRCBO) {
                        enabledOptions = []
                        let error = '3P+N RCBOs are not compatible with bottom mounting busbar for a 3 phase main incomer.'
                        errors.push(error)
                    } else {
                        // CLIP-344 - From my understanding of your last comment, if we have a single phase incomer in the first row, 
                        // all other rows can’t have a 3 phase busbar at all? - True
                        if (mainSwitchDetails.IncomerPhase === 'Single Phase') {
                            enabledOptions = ['Single Phase']
                        } else {
                            enabledOptions = ['Single Phase', '3 Phase']
                        }
                    }
                }
            } else if (this.state.MountingPosition === 'Top') {
                if (any3PNRCBO) {
                    enabledOptions = ['3 Phase']
                } else {
                    // CLIP-344 - Please see above
                    if (mainSwitchDetails.IncomerPhase === 'Single Phase') {
                        enabledOptions = ['Single Phase']
                    } else {
                        enabledOptions = ['Single Phase', '3 Phase']
                    }
                }
            }
        }

        if (returnErrors !== undefined) {
            return errors
        } else {
            return enabledOptions
        }
    }

    // This function return array of possible busbars based on current user selections
    getPossibleBusbars(ignorePolesSelection) {
        return this.props.busbarsData.filter(busbar => {
            // Filter busbars that have the same positions
            if (this.state.MountingPosition === '') {
                return true
            } else if (busbar.MountingPosition === this.state.MountingPosition) {
                // Filter busbars so we get only those that are allowed to be on selected user row
                if (busbar.RowSelection === '') {
                    return true
                } else if (busbar.RowSelection === 'Any row' || (
                        (this.state.RowSelection === '1' && busbar.RowSelection === 'First Row') ||
                        (this.state.RowSelection !== '1' && busbar.RowSelection === 'Second/Third/Fourth Row')
                    )) {
                        // Filter busbars that match user selected Incomer Phase
                        if (this.state.BusBarPhases === '') {
                            return true
                        } else if (
                            (this.state.BusBarPhases === 'Single Phase' && busbar.IncomerPhase === 'Single Phase') ||
                            (this.state.BusBarPhases === '3 Phase' && busbar.IncomerPhase === '3 Phase')
                        ) {
                            if (this.state.BusBarPoles === '' || !!ignorePolesSelection) {
                                return true
                            } else if (parseInt(this.state.BusBarPoles, 10) === getNumberOfPolesFromStringExpression(busbar.NumberOfPoles)) {
                                return true
                            }
                        }
                }
            }

            // If the busbar doesn't match any conditions, then return false to indicate it doesn't match filter criterias
            return false
        })
    }

    getEnabledPolesOptions(returnErrors) {
        const possibleBusbars = this.getPossibleBusbars(true)

        let enabledOptions = []
        let errors = []
        // First add all available options from all possible busbar combinations left
        possibleBusbars.forEach(busbar => {
            const busbarPoles = getNumberOfPolesFromStringExpression(busbar.NumberOfPoles)
            if (enabledOptions.indexOf(busbarPoles) === -1) enabledOptions.push('' + busbarPoles)
        })

        if (this.state.BusBarPhases !== '') {
            console.log('Enabled options before performing further checks: ', enabledOptions)
            
            // Check if busbar length would even fit
            const isRowWithMainSwitch = this.state.RowSelection === '1'
            const mainSwitchPosition = parseInt(this.props.board.MainSwitchPosition, 10)
            const selectedRowIndex = parseInt(this.state.RowSelection, 10) - 1
            let shouldHaveNeutralTerminalBlock = false
            let rowDevices = this.props.breakersAttachedToTheBoard.filter(existingBreaker => existingBreaker.RowIndex === selectedRowIndex)
            let connectableDevices = this.getBusbarConnectableDevices(rowDevices, this.state.MountingPosition)

            if (isRowWithMainSwitch) {
                // Don't consider breakers on the left of the main switch
                connectableDevices = connectableDevices.filter(breaker => {
                    return breaker.PoleIndex > mainSwitchPosition
                })

                if (this.state.MountingPosition === 'Top') {
                    shouldHaveNeutralTerminalBlock = true
                }
            }
            
            let poleIndexOfFirstConnectableDevice = 0
            let isIncompatibleResimaxWithCurrentEnclousure = (this.props.polesNeeded === '12x2' || this.props.polesNeeded === '12x3') && this.state.BusBarPhases === '3 Phase'
            if (isIncompatibleResimaxWithCurrentEnclousure) {
                enabledOptions = []
                let error = '3 phase busbar along with the connectors will not fit easily into enclosures with sizes 12x2 and 12x3. Please select a different enclosure size for 3 phase busbar.'
                errors.push(error)
            } else if ((
                    (connectableDevices.length >= 2 && this.state.MountingPosition === 'Bottom') ||
                    (connectableDevices.length >= 1 && this.state.MountingPosition === 'Top')  
                )
                && connectableDevices[0].PoleIndex !== undefined) {
                poleIndexOfFirstConnectableDevice = connectableDevices[0].PoleIndex

                let lastConnectableBreaker = connectableDevices[connectableDevices.length - 1]
                let busbarStartIndex = BusbarService.getBusbarStartIndex(this.state.MountingPosition, isRowWithMainSwitch, mainSwitchPosition, shouldHaveNeutralTerminalBlock, poleIndexOfFirstConnectableDevice)
                let busbarRequiredLengthToCoverAllDevices = BusbarService.getBusbarMinimumLengthToCoverAllRowDevices(busbarStartIndex, lastConnectableBreaker.PoleIndex, parseInt(lastConnectableBreaker.BreakerTypeReadOnly.SpaceUsed, 10))

                console.log('Based on the selections the row will require a busbar with minimum length of ' + busbarRequiredLengthToCoverAllDevices + ' poles.')

                let anyAvailableOptionsBeforeCheckingBusbarLength = enabledOptions.length > 0
                enabledOptions = enabledOptions.filter(option => {
                    const optionAsInteger = parseInt(option, 10)
                    // We don't disable longer busbars anymore, because even long busbars can fit on the board, they will be just cut to fit to the
                    // last connectable RCBO and the remaining busbar poles will determinate how many extra poles the user can select
                    // Now we check if the length of the busbar will be able to cover all connectable devices on the row, if not then it's too short
                    // and we will disable it
                    if (optionAsInteger >= busbarRequiredLengthToCoverAllDevices) {
                        return true
                    } else {
                        let error = 'Disabled ' + optionAsInteger + ' poles busbar option, because ' + optionAsInteger  + ' would be to short to cover all connectable devices on the selected row.'
                        console.log(error)
                        // errors.push(error)
                        return false
                    }
                })

                if (anyAvailableOptionsBeforeCheckingBusbarLength && enabledOptions.length === 0) {
                    let error = 'No busbar possible options to select, because no options would fit on the row at the starting position ' + (busbarStartIndex + 1) + ', consider moving your main switch to the left or change the position of the breakers.'
                    console.log(error)
                    errors.push(error)
                }
            } else if (connectableDevices.length === 1 && this.state.MountingPosition === 'Bottom') {
                enabledOptions = []
                let error = 'You need to have at least two connectable device in order to proceed.'
                errors.push(error)
            } else if (connectableDevices.length === 0) {
                enabledOptions = []
                let error = 'No connectable devices were found on the selected row, you need to have at least two connectable device in order to proceed.'
                errors.push(error)
            }
        }

        if (returnErrors !== undefined) {
            return errors
        } else {
            return enabledOptions
        }
    }

    resetAvailabilityValidation() {
        this.setState({ validationMessage: '', isOutOfStock: false })
    }

    setMountingPosition(value) {

        this.resetAvailabilityValidation()

        // If selecting Mounting position, reset all values after that
        this.setState({ 
            isAllowedToProceed: true, 
            errorInfo: '', 
            MountingPosition: value, 
            RowSelection: '', 
            BusBarPhases: '', 
            BusBarPoles: '',
            BusBarExtraPoles: 0,
            selectedBusBarData: null,
            isExtraPolesFieldEnabled: false
        })
    }

    setRowSelection(value) {
        this.resetAvailabilityValidation()

        // We need to reset some values in case user select different row, we reset the values that were selected after
        this.setState({ 
            isAllowedToProceed: true, 
            errorInfo: '', 
            BusBarPhases: '', 
            BusBarPoles: '', 
            BusBarExtraPoles: 0, 
            selectedBusBarData: null, 
            isExtraPolesFieldEnabled: false 
        })

        // We need to determinate if the row selected in that mounting position has RDC-4.5kA or RCD-6kA or MCB only or 2 phases
        // In that case the busbar is disabled for those devices and we need to display a message to the user and prevent going forward
        const selectedRowNumber = parseInt(value, 10)
        const selectedRowIndex = selectedRowNumber - 1 // Value selected by the user is not array based index which we need in this case
        const existingRowBreakers = this.props.breakersAttachedToTheBoard.filter(existingBreaker => existingBreaker.RowIndex === selectedRowIndex)
        const existingRowContactors = this.props.contactorsAttachedToTheBoard.filter(existingContactor => existingContactor.RowIndex === selectedRowIndex)

        // We find all breakers from first and last connectable devices
        const firstLastCompatibleBreakersRange = this.getBusbarConnectableDevices(existingRowBreakers, this.state.MountingPosition)
        const incompatibleBreakers = this.getIncompatibleBusbarBreakers(firstLastCompatibleBreakersRange, this.state.MountingPosition)

        let incompatibleContactors = [] 

        // We need to check that there are at least 2 compatible devices on the row to proceed
        if (firstLastCompatibleBreakersRange.length < 2) {
            this.setState({ errorInfo: 'You need to have at least two connectable devices in order to proceed.', isAllowedToProceed: false })
        } else {
            // Find if there are any contactors in between all compatible devices, the position of the contactor is greater
            // than the position of the first compatible breaker and less than the position of the last compatible breaker
            incompatibleContactors = existingRowContactors.filter(contactor => {
                const firstBreaker = first(firstLastCompatibleBreakersRange)
                const lastBreaker = last(firstLastCompatibleBreakersRange)
                return contactor.PoleIndex > firstBreaker.PoleIndex && contactor.PoleIndex < lastBreaker.PoleIndex
            })
        }

        // We throw an error if there are any incompatible devices in between
        if (incompatibleBreakers.length > 0) {
            this.setState({ errorInfo: `We have found an incompatible breaker "${incompatibleBreakers[0].BreakerTypeReadOnly.TypeOfDevice}" in between connectable busbar devices on postion ${incompatibleBreakers[0].PoleIndex + 1}, please remove it before proceeding.`, isAllowedToProceed: false })
        }

        if (incompatibleContactors.length > 0) {
            this.setState({ errorInfo: `We have found an incompatible contactor "${incompatibleContactors[0].ContractorReadOnly.Description}" in between connectable busbar devices on postion ${incompatibleContactors[0].PoleIndex + 1}, please remove it before proceeding.`, isAllowedToProceed: false })
        }

        firstLastCompatibleBreakersRange.forEach(breaker => {
            if (breaker[this.state.MountingPosition + 'PowerTag'] === true) {
                // Check if there are any breakers that have a power tag in that mounting position in that row
                this.setState({ errorInfo: 'Row already contains a breaker with a power tag in the selected mounting position, can not add a busbar, please remove any existing power tags.', isAllowedToProceed: false })
            } 
        })

        // Check if the row already contains a busbar in that position
        this.props.existingBusbars.forEach(existingBusbar => {
            if (existingBusbar.Row === selectedRowNumber) {
                this.setState({ errorInfo: 'There is already a busbar on that row.', isAllowedToProceed: false })
            }
        })

        this.setState({ RowSelection: value })
    }

    // Once poles number is select we can determinate which busbar data it refers to, we store it to perform some extra checks and for easier reference
    handlePolesSelection(value) {
        this.resetAvailabilityValidation()

        // Also whenever different poles quantity is selected we need to restart extra poles value
        this.setState({ BusBarPoles: value, BusBarExtraPoles: 0, selectedBusBarData: null, isExtraPolesFieldEnabled: false }, () => {
            const possibleBusbars = this.getPossibleBusbars() // This should return only one value
            if (possibleBusbars.length > 1) {
                window.alert('Could not determinate a unique busbar selection, please contact administrator.')
            } else {
                const availabilityDataForSelectedBusbar = this.props.residentialAllPartsAvailable.find(item => item.ItemCode === possibleBusbars[0].Reference)
                let canProceed = false
                if (!availabilityDataForSelectedBusbar.IsInStock) {
                    this.setState({
                        validationMessage: `${possibleBusbars[0].Description} is out of stock. Please, contact <a href="mailto:au.estimating@se.com">au.estimating@se.com</a>`,
                        isOutOfStock: true
                    })
                } else if (availabilityDataForSelectedBusbar.IsLowStock) {
                    canProceed = true
                    this.setState({
                        validationMessage: 'Low stock, longer lead times may apply',
                        isOutOfStock: false
                    })
                } else {
                    canProceed = true
                    this.resetAvailabilityValidation()
                }

                if (!canProceed) return

                this.setState({ selectedBusBarData: possibleBusbars[0] }, () => {
                    // We also need to determinate if any extra poles can be selected
                    this.setState({ isExtraPolesFieldEnabled: this.getMaximumExtraPoles() > 0 }, () => {
                        // What we want to do here is that instead of having 0 extra poles after selecting a busbar length
                        // we want to defaul to the maximum number of extra poles allowed, so the user doesn't have to click
                        // This is because with 0 extra poles behaviour in real life scenario the busbars would be cut to the
                        // last connectable device, which caused issues as not expected behaviour in initial lunch of the platform
                        this.changeExtraPolesQuantity(this.getMaximumExtraPoles()) 
                    })
                })

            }
        })
    }

    getMaximumExtraPoles() {
        // We already should have checks at this stage where it will be determinated if the busbar length is possible to fit on the row
        // Now we just check how many extra poles are left on the row
        const isRowWithMainSwitch = this.state.RowSelection === '1'
        const mainSwitchPosition = parseInt(this.props.board.MainSwitchPosition, 10)
        const selectedRowIndex = parseInt(this.state.RowSelection, 10) - 1
        let shouldHaveNeutralTerminalBlock = false
        let rowDevices = this.props.breakersAttachedToTheBoard.filter(existingBreaker => existingBreaker.RowIndex === selectedRowIndex)
        let orderedRowDevices = orderBy(rowDevices, ['PoleIndex'])
        let connectableDevices = this.getBusbarConnectableDevices(orderedRowDevices, this.state.MountingPosition)
        let incompatibleDevicesOnRightSideOfLastCompatibleDevice = orderedRowDevices.filter(breaker => breaker.PoleIndex > connectableDevices[connectableDevices.length -1].PoleIndex)
        let firstIncompatibleDevicePoleIndexOnRightSideOfLastCompatibleDevice = null
        if (incompatibleDevicesOnRightSideOfLastCompatibleDevice.length > 0) {
            firstIncompatibleDevicePoleIndexOnRightSideOfLastCompatibleDevice = parseInt(incompatibleDevicesOnRightSideOfLastCompatibleDevice[0].PoleIndex, 10)
        }

        if (isRowWithMainSwitch) {
            // Don't consider breakers on the left of the main switch
            connectableDevices = connectableDevices.filter(breaker => {
                return breaker.PoleIndex > mainSwitchPosition
            })

            if (isRowWithMainSwitch && this.state.MountingPosition === 'Top') {
                shouldHaveNeutralTerminalBlock = true
            }
        }

        // So we know if the row should have the neutral terminal block, but check as well if we will be actually moving automatically the devices
        // so we can better determine the maximum extra poles
        let willBeMovingDevicesToMakeSpaceForNeutralTerminalBlock = false
        if (shouldHaveNeutralTerminalBlock) {
            const selectedRowNumber = parseInt(this.state.RowSelection, 10)
            const selectedRowIndex = selectedRowNumber - 1 // Value selected by the user is not array based index which we need in this case
            const existingRowBreakers = this.props.breakersAttachedToTheBoard.filter(existingBreaker => existingBreaker.RowIndex === selectedRowIndex)
            let isPositionBeforeMainSwitchOccupied = false
            isPositionBeforeMainSwitchOccupied = existingRowBreakers.some(breaker => {
                let positionsOccupiedByExistingDevice = []
                const spaceUsedByExistingDevice = parseInt(breaker.BreakerTypeReadOnly.SpaceUsed, 10)
                for (let index = breaker.PoleIndex; index < breaker.PoleIndex + spaceUsedByExistingDevice; index++) {
                    positionsOccupiedByExistingDevice.push(index)    
                }

                let intersectingElements = intersectionWith([mainSwitchPosition - 1], positionsOccupiedByExistingDevice, isEqual)
                
                return intersectingElements.length > 0
            })

            // We need to check the case of surge protection
            if (mainSwitchPosition === 1 && this.props.board.SPDID !== null && this.props.board.SPDID !== 0) {
                isPositionBeforeMainSwitchOccupied = true
            }

            if (mainSwitchPosition === 0 || isPositionBeforeMainSwitchOccupied) {
                if (this.canDevicesBeMovedToMakeSpaceForNeutralTerminalBlock()) {
                    willBeMovingDevicesToMakeSpaceForNeutralTerminalBlock = true
                }
            }
        }

        console.warn('willBeMovingDevicesToMakeSpaceForNeutralTerminalBlock', willBeMovingDevicesToMakeSpaceForNeutralTerminalBlock)

        let poleIndexOfFirstConnectableDevice = 0
        if (connectableDevices.length > 0 && connectableDevices[0].PoleIndex !== undefined) {
            poleIndexOfFirstConnectableDevice = connectableDevices[0].PoleIndex
        }
        let lastConnectableBreaker = connectableDevices[connectableDevices.length - 1]
        let busbarStartIndex = BusbarService.getBusbarStartIndex(this.state.MountingPosition, isRowWithMainSwitch, mainSwitchPosition, shouldHaveNeutralTerminalBlock, poleIndexOfFirstConnectableDevice)
        let busbarMaximumExtraPoles = BusbarService.getMaximumAllowedExtraPoles(busbarStartIndex, lastConnectableBreaker.PoleIndex, parseInt(lastConnectableBreaker.BreakerTypeReadOnly.SpaceUsed, 10), parseInt(this.state.BusBarPoles, 10), this.state.boardRowPoles, firstIncompatibleDevicePoleIndexOnRightSideOfLastCompatibleDevice, willBeMovingDevicesToMakeSpaceForNeutralTerminalBlock)
 
        console.log('Maximum number of Extra Poles allowed', busbarMaximumExtraPoles)

        return busbarMaximumExtraPoles
    }

    changeExtraPolesQuantity(updatedQuantity) {
        let maximumExtraPoles = this.getMaximumExtraPoles()

        const updatedQuantityAsNumber = parseInt(updatedQuantity, 10)
        if (updatedQuantityAsNumber > 0) {
            if (updatedQuantityAsNumber < maximumExtraPoles) {
                this.setState({ BusBarExtraPoles: updatedQuantityAsNumber })
            } else {
                this.setState({ BusBarExtraPoles: maximumExtraPoles })
            }
        } else {
            this.setState({ BusBarExtraPoles: 0 })
        }
    }
    

    decreaseQuantity() {
        if (this.state.BusBarExtraPoles > 0) this.setState({ BusBarExtraPoles: this.state.BusBarExtraPoles - 1 })
    }

    increaseQuantity() {
        let maximumExtraPoles = this.getMaximumExtraPoles()
        if (this.state.BusBarExtraPoles < maximumExtraPoles) this.setState({ BusBarExtraPoles: this.state.BusBarExtraPoles + 1 })
    }

    canDevicesBeMovedToMakeSpaceForNeutralTerminalBlock() {
        // Get the list of all compatible busbar devices on the row (breakers & fitted options)
        const existingRowBreakers = this.props.breakersAttachedToTheBoard.filter(breaker => breaker.RowIndex === parseInt(this.state.RowSelection, 10) - 1)
        const existingRowFittedOptions = this.props.fittedOptionsAttachedToTheBoard.filter(device => device.RowIndex === parseInt(this.state.RowSelection, 10) - 1)
        const existingRowContactors = this.props.contactorsAttachedToTheBoard.filter(contactor => contactor.RowIndex === parseInt(this.state.RowSelection, 10) - 1)
        const existingRowDevices = [...existingRowBreakers, ...existingRowFittedOptions, ...existingRowContactors]
        const orderedRowDevices = orderBy(existingRowDevices, ['PoleIndex'])

        // Note: NTB is always 1 pole, so we always will need to move breakers only by 1 pole

        // Get the last device on the row (breaker, contactor, etc.) and see if it can be moved by one pole, because if we can then all devices between the last one and the main switch can be moved
        const lastDeviceOnRow = orderedRowDevices[orderedRowDevices.length - 1]

        let lastDeviceType = 'BreakerTypeReadOnly'
        if (lastDeviceOnRow.FittedOptionReadOnly !== undefined) {
            lastDeviceType = 'FittedOptionReadOnly'
        } else if (lastDeviceOnRow.ContractorReadOnly !== undefined) {
            lastDeviceType = 'ContractorReadOnly'
        }

        let canMoveBrekerByOnePole = false
        if (lastDeviceType === 'BreakerTypeReadOnly') {
            canMoveBrekerByOnePole = canAddDeviceToBoardPosition(lastDeviceOnRow[lastDeviceType].SpaceUsed, lastDeviceOnRow.RowIndex, lastDeviceOnRow.PoleIndex + 1, false, this.props.board, this.props.boardDetails, this.props.boardOptionData, false, lastDeviceOnRow.ItemID)
        } else if (lastDeviceType === 'FittedOptionReadOnly') {
            canMoveBrekerByOnePole = canAddDeviceToBoardPosition(lastDeviceOnRow[lastDeviceType].SpaceUsed, lastDeviceOnRow.RowIndex, lastDeviceOnRow.PoleIndex + 1, false, this.props.board, this.props.boardDetails, this.props.boardOptionData, false, null, null, lastDeviceOnRow.ItemID)
        } else if (lastDeviceType === 'ContractorReadOnly') {
            canMoveBrekerByOnePole = canAddDeviceToBoardPosition(lastDeviceOnRow[lastDeviceType].SpaceUsed, lastDeviceOnRow.RowIndex, lastDeviceOnRow.PoleIndex + 1, false, this.props.board, this.props.boardDetails, this.props.boardOptionData, false, null, lastDeviceOnRow.ItemID)
        }
        

        console.log('Can automatically move devices on the row to make space for NTB?', canMoveBrekerByOnePole)
        return canMoveBrekerByOnePole
    }

    automaticallyMoveRowDevicesToMakeSpaceForNeutralTerminalBlock() {
        const existingRowOrderedBreakers = orderBy(this.props.breakersAttachedToTheBoard.filter(breaker => breaker.RowIndex === parseInt(this.state.RowSelection, 10) - 1), ['PoleIndex'])
        const existingRowOrderedFittedOptions = orderBy(this.props.fittedOptionsAttachedToTheBoard.filter(fittedOption => fittedOption.RowIndex === parseInt(this.state.RowSelection, 10) - 1), ['PoleIndex']) 
        const existingRowOrderedContactors = orderBy(this.props.contactorsAttachedToTheBoard.filter(contactor => contactor.RowIndex === parseInt(this.state.RowSelection, 10) - 1), ['PoleIndex'])
        const breakersOnRightOfTheMainSwitch = existingRowOrderedBreakers.filter(breaker => breaker.PoleIndex > parseInt(this.props.board.MainSwitchPosition, 10))
        const fittedOptionsOnRightOfTheMainSwitch = existingRowOrderedFittedOptions.filter(fittedOption => fittedOption.PoleIndex > parseInt(this.props.board.MainSwitchPosition, 10))
        const contactorsOnRightOfTheMainSwitch = existingRowOrderedContactors.filter(contactor => contactor.PoleIndex > parseInt(this.props.board.MainSwitchPosition, 10))
        
        console.log('breakersOnRightOfTheMainSwitch', breakersOnRightOfTheMainSwitch)
        breakersOnRightOfTheMainSwitch.forEach(breaker => {
            this.updateBreakerPosition(breaker.ItemID, breaker.RowIndex, breaker.PoleIndex + 1, breaker.Note, true)
        })
        console.log('fittedOptionsOnRightOfTheMainSwitch', fittedOptionsOnRightOfTheMainSwitch)
        fittedOptionsOnRightOfTheMainSwitch.forEach(fittedOption => {
            this.updateFittedOptionPosition(fittedOption.ItemID, fittedOption.RowIndex, fittedOption.PoleIndex + 1, fittedOption.Note, true)
        })

        console.log('contactorsOnRightOfTheMainSwitch', contactorsOnRightOfTheMainSwitch)
        contactorsOnRightOfTheMainSwitch.forEach(contactor => {
            this.updateContactorPosition(contactor.ItemID, contactor.RowIndex, contactor.PoleIndex + 1, contactor.Note, true)
        })

        this.updateMainSwitchPosition(parseInt(this.props.board.MainSwitchPosition, 10) + 1)
    }

    saveBusbar() {
        // Before we allow adding a busbar we need to check if there is a space for neutral block, if there isn't then we need to ask
        // user to make a space on indicated position, we don't support switching elements for the user at this stage
        const busbarsWithNeutralBlock = BusbarService.getBusbarsWithNeutralTerminalBlockReferences()
        const busbarErrorMessage = 'This Busbar requires a Neutral Terminal Block to be inserted on left side of the main switch. Please make space available.'
        const mainSwitchPosition = parseInt(this.props.board.MainSwitchPosition, 10)
        let isPositionBeforeMainSwitchOccupied = false
        let isAutomaticallyUpdatingDevices = false
        if (busbarsWithNeutralBlock.indexOf(this.state.selectedBusBarData.Reference) > -1) {
            if (mainSwitchPosition === 0) {
                // Here we know that we need a NTB before the main switch, but if the main switch is on the very first position then we check if we can move it and we move it
                // before doing other checks etc., otherwise we just throw an error for user to take manual action
                if (this.canDevicesBeMovedToMakeSpaceForNeutralTerminalBlock()) {
                    // We need to move the main switch by one pole to the right
                    console.warn('Automatically moving devices on the row to make space for NTB')
                    this.automaticallyMoveRowDevicesToMakeSpaceForNeutralTerminalBlock()
                    isAutomaticallyUpdatingDevices = true
                } else {   
                    this.setState({ errorInfo: busbarErrorMessage })
                    return false
                }
            }

            // Here we know busbar requires a neutral block which needs to go on first row, first position and occupies 1 space
            const selectedRowNumber = parseInt(this.state.RowSelection, 10)
            const selectedRowIndex = selectedRowNumber - 1 // Value selected by the user is not array based index which we need in this case
            const existingRowBreakers = this.props.breakersAttachedToTheBoard.filter(existingBreaker => existingBreaker.RowIndex === selectedRowIndex)
            isPositionBeforeMainSwitchOccupied = existingRowBreakers.some(breaker => {
                let positionsOccupiedByExistingDevice = []
                const spaceUsedByExistingDevice = parseInt(breaker.BreakerTypeReadOnly.SpaceUsed, 10)
                for (let index = breaker.PoleIndex; index < breaker.PoleIndex + spaceUsedByExistingDevice; index++) {
                    positionsOccupiedByExistingDevice.push(index)    
                }

                let intersectingElements = intersectionWith([mainSwitchPosition - 1], positionsOccupiedByExistingDevice, isEqual)
                
                return intersectingElements.length > 0
            })

            // We need to check the case of surge protection
            if (mainSwitchPosition === 1 && this.props.board.SPDID !== null && this.props.board.SPDID !== 0) {
                isPositionBeforeMainSwitchOccupied = true
            }

            if (isPositionBeforeMainSwitchOccupied) {
                if (this.canDevicesBeMovedToMakeSpaceForNeutralTerminalBlock()) {
                    console.warn('Automatically moving devices on the row to make space for NTB')
                    this.automaticallyMoveRowDevicesToMakeSpaceForNeutralTerminalBlock()
                    isAutomaticallyUpdatingDevices = true
                } else {   
                    this.setState({ errorInfo: busbarErrorMessage })
                    return false
                }
            }
        }

        let busbarStartPosition = 0
        let busbarLength = 0

        const isRowWithMainSwitch = this.state.RowSelection === '1'
        const selectedRowIndex = parseInt(this.state.RowSelection, 10) - 1
        let shouldHaveNeutralTerminalBlock = false
        let rowDevices = this.props.breakersAttachedToTheBoard.filter(existingBreaker => existingBreaker.RowIndex === selectedRowIndex)
        let connectableDevices = this.getBusbarConnectableDevices(rowDevices, this.state.MountingPosition)

        if (isRowWithMainSwitch && this.state.MountingPosition === 'Top') {
            shouldHaveNeutralTerminalBlock = true
        }

        let poleIndexOfFirstConnectableDevice = 0
        if (connectableDevices.length > 0 && connectableDevices[0].PoleIndex !== undefined) {
            poleIndexOfFirstConnectableDevice = connectableDevices[0].PoleIndex
        }

        let lastConnectableBreaker = connectableDevices[connectableDevices.length - 1]
        let busbarStartIndex = BusbarService.getBusbarStartIndex(this.state.MountingPosition, isRowWithMainSwitch, mainSwitchPosition, shouldHaveNeutralTerminalBlock, poleIndexOfFirstConnectableDevice)
        busbarStartPosition = busbarStartIndex + 1

        // If we are moving the devices then save starting position of busbar as one pole to the right
        if (isAutomaticallyUpdatingDevices) {
            busbarStartPosition = busbarStartPosition + 1
        }

        let busbarRequiredLengthToCoverAllDevices = BusbarService.getBusbarMinimumLengthToCoverAllRowDevices(busbarStartIndex, lastConnectableBreaker.PoleIndex, parseInt(lastConnectableBreaker.BreakerTypeReadOnly.SpaceUsed, 10))
        busbarLength = busbarRequiredLengthToCoverAllDevices

        this.props.addBusBarToBoard(this.state.selectedBusBarData.ItemID, parseInt(this.state.RowSelection, 10), this.state.BusBarExtraPoles, busbarStartPosition, busbarLength)
    }

    render() {
        return (
            <form className="form">   
                <p className="alert alert--success">
                    <span className="alert__icon">
                        <span className="i-check-circle"></span>
                    </span>
                    <strong className="alert__text">
                        Please complete adding all needed RCBOs in the board before adding a Busbar.
                    </strong>
                </p>

                {
                    this.state.errorInfo !== '' &&
                    <p className="alert alert--error">
                        <span className="alert__icon">
                            <span className="i-exclamation"></span>
                        </span>
                        <span className="alert__text">
                            { this.state.errorInfo }
                        </span>
                    </p>
                }

                {
                    this.state.BusBarPhases !== '' && this.getEnabledPolesOptions(true).length > 0 &&
                    <p className="alert alert--error">
                        <span className="alert__icon">
                            <span className="i-exclamation"></span>
                        </span>
                        <span className="alert__text">
                            { 
                                this.getEnabledPolesOptions(true).map(error => {
                                    return <span>{ error }</span>
                                })
                            }
                        </span>
                    </p>
                }

                {
                    this.state.RowSelection !== '' && this.getEnabledPhasesOptions(true).length > 0 &&
                    <p className="alert alert--error">
                        <span className="alert__icon">
                            <span className="i-exclamation"></span>
                        </span>
                        <span className="alert__text">
                            { 
                                this.getEnabledPhasesOptions(true).map(error => {
                                    return <span>{ error }</span>
                                })
                            }
                        </span>
                    </p>
                }

                <div className="row">
                    <div className="col-4">
                        <RadioObjectGroup
                            title="Mounting Position"
                            options={[{
                                "value": "Top",
                                "label": "Top",
                                "warning": "",
                                "enabled": true
                            }, {
                                "value": "Bottom",
                                "label": "Bottom",
                                "warning": "",
                                "enabled": true
                            }
                            ]}
                            selectedValue={ this.state.MountingPosition }
                            selecteCallback={value => this.setMountingPosition(value) }
                        />
                    </div>
                    <div className="col-4">
                        <RadioObjectGroup
                            title="Row selection"
                            options={[{
                                "value": "1",
                                "label": "First row",
                                "enabled": true
                            }, {
                                "value": "2",
                                "label": "Second row",
                                "enabled": true
                            }, {
                                "value": "3",
                                "label": "Third row",
                                "enabled": true
                            }, {
                                "value": "4",
                                "label": "Fourth row",
                                "enabled": true
                            }
                            ]}
                            enabledOptions={ this.getEnabledRowSelections() }
                            enabled={ this.state.MountingPosition !== '' }
                            selectedValue={ this.state.RowSelection }
                            selecteCallback={value => this.setRowSelection(value)}
                        />
                    </div>
                    <div className="col-4">
                        <RadioObjectGroup
                            title="Phases"
                            options={[{
                                "value": "Single Phase",
                                "label": "Single Phase",
                                "enabled": true
                            }, {
                                "value": "3 Phase",
                                "label": "3 Phases",
                                "enabled": true
                            }
                            ]}
                            enabledOptions={ this.getEnabledPhasesOptions() }
                            enabled={ this.state.RowSelection !== '' && this.state.isAllowedToProceed }
                            selectedValue={ this.state.BusBarPhases }
                            selecteCallback={value => this.setState({ BusBarPhases: value }) }
                        />
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-9">
                        <RadioObjectGroup
                            title="Poles"
                            options={[{
                                "value": "5",
                                "label": "05",
                                "enabled": true
                            }, {
                                "value": "12",
                                "label": "12",
                                "enabled": true
                            }, {
                                "value": "15",
                                "label": "15",
                                "enabled": true
                            }, {
                                "value": "21",
                                "label": "21",
                                "enabled": true
                            }, {
                                "value": "24",
                                "label": "24",
                                "enabled": true
                            }]}
                            enabledOptions={ this.getEnabledPolesOptions() }
                            enabled={ this.state.BusBarPhases !== '' && this.state.isAllowedToProceed }
                            selectedValue={ this.state.BusBarPoles }
                            layout={"layout-md-btn radio-button-row"}
                            selecteCallback={value => this.handlePolesSelection(value) }
                        />
                    </div>
                    <div className="col-3">
                        <div className={'form__group form__group--step' + (this.state.isExtraPolesFieldEnabled ? ' is-active' : '') + (this.state.BusBarExtraPoles > 0 ? ' is-done' : '')}>
                            <h4 className="form-label-step">
                                <div className="form-label-step__icon">
                                    <span className="i-check"></span>
                                </div>
                                <span className="form-label-step__text">Extra Poles</span>
                            </h4>
                            <div className="form__control">
                                <div className="quantity">
                                    <button type="button" className={'button button--markup decrease' + (!this.state.isExtraPolesFieldEnabled ? ' is-disabled' : '')}
                                        onClick={ () => this.decreaseQuantity() }>
                                    <span className="button__icon">
                                        <span className="i-remove"></span>
                                    </span>
                                    </button>
                                    <div className="quantity__amount">
                                        <div className="quantity__amount-input">
                                            <input type="number" value={this.state.BusBarExtraPoles} 
                                                onChange={(event) => { this.changeExtraPolesQuantity(event.target.value)}}
                                                className={'form__input input' + (!this.state.isExtraPolesFieldEnabled ? ' is-disabled' : '')} />
                                        </div>
                                    </div>
                                    <button type="button" className={'button button--markup increase' + (!this.state.isExtraPolesFieldEnabled || this.getMaximumExtraPoles() === this.state.BusBarExtraPoles ? ' is-disabled' : '')}
                                        onClick={ () => this.increaseQuantity() }>
                                        <span className="button__icon">
                                            <span className="i-plus"></span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    this.state.validationMessage &&
                    <div className="modal__validation-msg">
                        <span className="alert__icon">
                            <span className="i-exclamation"></span>
                        </span>
                        <span dangerouslySetInnerHTML={{ __html: this.state.validationMessage }}></span>
                    </div>
                }

                <div className="form__actions">
                    <button type="button" className={'button button--1' + (this.state.selectedBusBarData === null || this.state.errorInfo !== '' ? ' is-disabled' : '')}
                        onClick={() => this.saveBusbar() }>
                        <span className="button__text">
                            Add Busbar
                        </span>
                    </button>
                </div>

            </form>
        )
    }
}